import React from 'react'
import loadable from '@loadable/component'
import {aboutHeader, fullParagraphContent, headerParagraph, checkTheme } from '../utils/informations'
import Layout from '../layouts'
import background from '../assets/about-us-header2.webp'
import Paragraph from '../components/paragraph/paragraph'

const SecondaryPagesHeader = loadable(() => import('../components/secondary-pages-header/secondary-pages-header'))
const FullScreenParagraph = loadable(() => import("../components/fullscreen-paragraph/fullscreen-paragraph"))
const AboutUs = () => {

  return (
    <Layout pageTitle={`About Us - ${checkTheme().pageTitle}`}>
      
         <SecondaryPagesHeader title={'About Us'} background={background} description={aboutHeader.description} checks={aboutHeader.checks} imgwebp={aboutHeader.imgwebp} imgpng={aboutHeader.imgpng}/>

         <Paragraph props={headerParagraph} origin="About Us"/>

        <FullScreenParagraph props={fullParagraphContent} origin="About Us"/>
    </Layout>
  )
}

export default AboutUs